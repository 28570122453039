import styled from "@emotion/styled"
import { graphql, navigate } from "gatsby"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Box, Flex } from "theme-ui"
import SaleBadge from "../assets/icons/sale-badge"
import Button from "../components/base/buttons"
import Text from "../components/base/text/text"
import Color from "../components/color/color"
import ContentModules from "../components/content-modules"
import { CloseContainer } from "../components/drawers/modal-drawer"
import VariantPrice from "../components/product/price"
import { RelatedProducts } from "../components/product/related-products"
import { ProductContainer } from "../components/product/product-highlight"
import RichText from "../components/rich-text"
import SEO from "../components/seo"
import SizeGuide from "../components/size-guide"
import TabMenu from "../components/tab-menu"
import { InterfaceContext } from "../context/interface-context"
import StoreContext from "../context/store-context"
import useProductVariants from "../hooks/use-product-variants"
import useDisableBackgroundScroll from "../hooks/useDisableBackgroundScroll"
import useQuery from "../hooks/useQuery"
import { trackAddToCart, trackProductViewed } from "../services/analytics"
import { createClient } from "../util/client"
import { handleMedia } from "../util/handlers"
import { formatVariantPrice, salePercentage } from "../util/prices"
import { sortVariants } from "../util/product-functions"

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  > div {
    flex: 1;
  }

  ${(props) => props.theme.bp.desktop} {
    flex-direction: row;
    padding-left: 30px;
    padding-right: 30px;
  }
`

const ImageContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(${(props) => props.length}, 100vw);

  overflow: auto;
  overflow-y: hidden;

  position: relative;

  margin-bottom: 30px;

  ${(props) => props.theme.bp.desktop} {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`

const InformationWrapper = styled(Box)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${(props) => props.theme.bp.desktop} {
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    right: 0;
    top: 100px;
    margin: 80px 0px ${({ theme }) => theme.space[2]}px 0px;
    height: 100%;
    padding: 0px ${({ theme }) => theme.space[2]}px;
  }
`

const InformationInner = styled(Flex)`
  flex-direction: column;
  justify-content: center;

  ${(props) => props.theme.bp.desktop} {
    width: 70%;
  }
`

const Options = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;

  > div:first-of-type,
  button:first-of-type {
    flex: 0 0 50px;
  }

  > div,
  button {
    flex: 0 0 50px;
  }
`

const Variant = styled(Button)`
  width: 52px;
  height: 40px;

  ${(props) =>
    props.isGiftCard &&
    `
    width: 60px;
  `}

  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) =>
      !props.disabled && !props.active && props.theme.colors.lightGreen};
    color: ${(props) => !props.disabled && props.theme.colors.white};
  }
`

const client = createClient()

const ProductPageTemplate = ({ data, pageContext }) => {
  const { fetchedColors, contentfulProduct: product } = data
  const {
    objectId,
    media,
    description,
    title,
    color,
    care,
    details,
    metaDescription,
    metaTitle,
    relatedProductsTitle,
    relatedProducts,
  } = product

  const { catSlug, colorOptions, sizeGuide } = pageContext
  const { cart, dispatch } = useContext(StoreContext)
  const { toggleModal, toggleCart, setModalContent } =
    useContext(InterfaceContext)
  const [isFixed, setIsFixed] = useState(true)
  const [chosen, setChosen] = useState()
  const [, setScrollable] = useDisableBackgroundScroll()

  const imageContainerRef = useRef()
  const infoContainerRef = useRef()

  const [queryData, setQuery] = useQuery(null)

  const { variants } = useProductVariants({
    productId: objectId,
    contentfulProduct: product,
  })

  const handleFixed = () => {
    if (imageContainerRef.current && infoContainerRef.current) {
      const imgContainerHeight = imageContainerRef.current.clientHeight
      const infoContainerHeight = infoContainerRef.current.clientHeight

      const dissapearingOffset = window.scrollY - 250

      if (
        imgContainerHeight > infoContainerHeight &&
        dissapearingOffset >= imgContainerHeight - infoContainerHeight
      ) {
        setIsFixed(false)
      } else {
        setIsFixed(true)
      }
    }
  }

  useEffect(() => {
    document.addEventListener("scroll", handleFixed, { passive: true })

    function fetchInventory() {
      if (variants) {
        const firstWithInventory = variants.find(
          (v) => v.inventory_quantity !== 0
        )

        if (firstWithInventory) {
          setChosen(firstWithInventory)
        }
      }
    }

    fetchInventory()

    return () => {
      document.removeEventListener("scroll", handleFixed, { passive: true })
    }
  }, [variants])

  useEffect(() => {
    if (product) {
      trackProductViewed(product)
    }
  }, [product])

  const handleAddToCartClicked = async () => {
    let size = chosen?.options?.[0]?.value || ""

    if (size === "Default Variant") {
      size = ""
    }

    setQuery(
      client.carts.lineItems
        .create(cart.id, {
          variant_id: chosen.objectId,
          metadata: { color: product?.color?.name, size },
          quantity: 1,
        })
        .then((data) => {
          // if we added the last item, remove chosen
          const variant = variants.find(
            (variant) => variant.objectId === chosen.objectId
          )
          if (variant.inventory_quantity === 1) {
            setChosen(null)
          }
          trackAddToCart(cart, product, variant, 1)
          dispatch({ type: "setCart", payload: data.cart })
          toggleCart()
          return data.cart
        })
        .catch(() => setChosen(null))
    )
  }

  const shouldShowBadge = useMemo(() => {
    if (variants?.length) {
      return variants[0].calculated_price < variants[0].original_price
    }
    return false
  }, [variants])

  return (
    <>
      <SEO
        title={metaTitle || title}
        description={metaDescription?.metaDescription || description}
      />
      <Container>
        <ImageContainer ref={imageContainerRef} length={media.length}>
          {shouldShowBadge && (
            <SaleBadge text={salePercentage(variants?.[0])} />
          )}
          {media &&
            media.map((m) => {
              return handleMedia(m, {
                imageClick: () => {
                  setModalContent(
                    <>
                      <CloseContainer
                        onClick={() => {
                          setScrollable(true)
                          toggleModal()
                        }}
                      >
                        <Text>Close</Text>
                      </CloseContainer>
                      <Flex
                        sx={{ flexDirection: "column", overflow: "scroll" }}
                      >
                        {media.map((m) =>
                          handleMedia(m, {
                            imageClick: () => {
                              setScrollable(true)
                              toggleModal()
                            },
                          })
                        )}
                      </Flex>
                    </>
                  )
                  setScrollable(false)
                  toggleModal()
                },
              })
            })}
        </ImageContainer>

        <InformationWrapper
          px={[2, 0]}
          isFixed={isFixed}
          ref={infoContainerRef}
        >
          <InformationInner>
            {title && (
              <Text sx={{ fontSize: 4, fontWeight: "bold" }} my={2}>
                {title}
              </Text>
            )}
            {description && <RichText body={description} />}
            {variants &&
              (product.handle === "gift-card" ? (
                <Text>From: {formatVariantPrice(cart, variants[0])}</Text>
              ) : (
                <VariantPrice cart={cart} variant={variants[0]} />
              ))}
            {variants && variants.length > 1 && (
              <Options my={2}>
                {sortVariants({ variants }).map((v) => {
                  const active = chosen?.objectId === v.objectId

                  const disabled =
                    v.inventory_quantity === 0 ||
                    v.inventory_quantity ===
                      cart?.items.find((item) => item.variant_id === v.objectId)
                        ?.quantity

                  const isGiftCard = product.handle === "gift-card"
                  const giftCardPrice = formatVariantPrice(cart, v)

                  return (
                    <Variant
                      key={v.id}
                      active={active}
                      variant={
                        disabled ? "disabled" : active ? "primary" : "option"
                      }
                      disabled={disabled}
                      showNet={true}
                      onClick={() => setChosen(v)}
                      isGiftCard={isGiftCard}
                      sx={{ padding: "0px" }}
                    >
                      {isGiftCard ? (
                        <Text sx={{ fontSize: 0 }}>{giftCardPrice}</Text>
                      ) : v.display ? (
                        <Text sx={{ fontSize: 0 }}>{v.display}</Text>
                      ) : v.options?.[0]?.value ? (
                        <Text sx={{ fontSize: 0 }}>
                          {v.options?.[0]?.value}
                        </Text>
                      ) : null}
                    </Variant>
                  )
                })}
              </Options>
            )}

            {colorOptions && colorOptions.length > 1 && (
              <Options my={2}>
                {colorOptions.map((c) => {
                  const { node: correctColor } = fetchedColors.edges.find(
                    (fc) => fc.node.id === c.id
                  )

                  return (
                    <Color
                      key={correctColor.id}
                      id={correctColor.id}
                      name={correctColor.name}
                      colorCode={correctColor.colorCode}
                      detailed={correctColor.detailed}
                      onClick={() => {
                        navigate(`/${catSlug}/${c.handle}`)
                      }}
                      isActive={color.id === c.id}
                    />
                  )
                })}
              </Options>
            )}
            {variants && chosen ? (
              <Button
                sx={{ width: "100%", height: "40px", minHeight: "40px" }}
                py={2}
                my={3}
                variant={!chosen ? "disabled" : "primary"}
                disabled={queryData?.loading || !chosen}
                loading={queryData?.loading}
                onClick={handleAddToCartClicked}
              >
                {variants.find((v) => v.objectId === chosen?.objectId)
                  ?.inventory_quantity === 0 ? (
                  <Text>Sold out</Text>
                ) : (
                  <Text>Add to cart</Text>
                )}
              </Button>
            ) : null}

            {(details || care || sizeGuide) && (
              <Box>
                <TabMenu
                  data={[
                    details !== null && {
                      id: "details",
                      name: "Details",
                      content: <RichText input={details} />,
                    },
                    care !== null && {
                      id: "care",
                      name: "Care",
                      content: <RichText input={care} />,
                    },
                    sizeGuide !== undefined &&
                      sizeGuide !== null && {
                        id: "sizing",
                        name: "Sizing",
                        content: <SizeGuide sizeGuide={sizeGuide} />,
                      },
                  ]}
                />
              </Box>
            )}
          </InformationInner>
        </InformationWrapper>
      </Container>

      {relatedProducts?.length > 0 && (
        <RelatedProducts
          title={relatedProductsTitle}
          products={relatedProducts}
        />
      )}

      {product.contentModules && (
        <ProductContainer my={2} mx={3}>
          <ContentModules allModules={product.contentModules} />
        </ProductContainer>
      )}
    </>
  )
}

export default ProductPageTemplate

export const query = graphql`
  query ($handle: String!, $colorIds: [String]!) {
    contentfulProduct(handle: { eq: $handle }) {
      ...ProductPage
    }
    fetchedColors: allContentfulColor(filter: { id: { in: $colorIds } }) {
      edges {
        node {
          ...Color
        }
      }
    }
  }
`
